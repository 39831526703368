const countries = document.querySelectorAll('[data-country]');
countries?.forEach(function (country) {
    country.addEventListener('click', function (event) {
        const country = event.target?.dataset.country || null;
        if (country) {
            const d = new Date();
            d.setTime(d.getTime() + (90*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = 'country=' + country + '; expires=' + expires + '; path=/';
        }
    });
});
