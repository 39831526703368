/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable new-cap */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
(
    function ($) {
        /**
         * @param element
         * @param options
         */
        function Maps(element, options) {
            /**
             * Current options set by the caller including defaults.
             */
            this.settings = $.extend({}, Maps.Defaults, options);

            /**
             * Currently suppressed events to prevent them from beeing retriggered.
             */
            this.supress = {};

            /**
             * The available providers.
             */
            this.providers = ['leaflet'];

            /**
             * Plugin element.
             */
            this.$element = $(element);

            /**
             * Maps stage element.
             */
            this.$stage = null;

            /**
             * Maps map object.
             */
            this.map = null;

            /**
             * Maps layers.
             */
            this.layers = {};

            /**
             * Maps radius object.
             */
            this.radius = null;

            /**
             * Maps markers.
             */
            this.markers = [];

            /**
             * Stored language for consistent UI
             */
            this.language = document.querySelector('html').getAttribute('lang') || 'en';

            this.initialize();
        }

        /**
         * Default options for Maps.
         */
        Maps.Defaults = {
            provider            : 'leaflet',
            center              : null,

            zoom                : 15,
            zoomControl         : true,
            view                : 'roadmap',
            viewControl         : true,
            routeControl        : false,
            scrollWheel         : false,

            // Google Maps tiles
            tiles               : {
                url                 : 'https://{s}.google.com/vt/lyrs={layer}&x={x}&y={y}&z={z}',
                domains             : ['mt0', 'mt1', 'mt2', 'mt3']
            },

            markerIcon          : {
                iconUrl             : '/assets/img/maps/marker.png',
                iconSize            : [26, 46],
                iconAnchor          : [13, 46]
            },

            radiusStyle         : {
                stroke              : false,
                fillColor           : '#0d4da5',
                fillOpacity         : 0.2
            },

            views               : ['roadmap', 'satellite'],

            elementClass        : 'maps',
            stageClass          : 'maps__stage',
            zoomControlsClass   : 'maps__zoom-controls',
            viewControlsClass   : 'maps__view-controls',
            routeControlClass   : 'maps__route-control',
            popupClass          : 'maps-popup'
        };

        /**
         * Initializes Maps.
         */
        Maps.prototype.initialize = function () {
            if (this.providers.indexOf(this.getProvider()) !== -1) {
                this.$element.addClass(this.settings.elementClass).append(
                    this.$stage = $('<div class="' + this.settings.stageClass + '" />')
                );

                if (this.$element.attr('data-maps-lat') && this.$element.attr('data-maps-lng')) {
                    this.settings.center = {
                        lat : this.$element.attr('data-maps-lat'),
                        lng : this.$element.attr('data-maps-lng')
                    };
                }

                if (this.$element.attr('data-maps-zoom')) {
                    this.settings.zoom = this.$element.attr('data-maps-zoom');
                }

                this.setMap();
                this.setLayers();
                this.setControls();
                this.setCenter(this.settings.center);

                $('[data-maps-marker]', this.$element).each($.proxy(function (i, marker) {
                    var $marker = $(marker).remove();

                    if ($marker.attr('data-maps-lat') && $marker.attr('data-maps-lng')) {
                        this.setMarker($marker.attr('data-maps-lat'), $marker.attr('data-maps-lng'), {
                            popup : $marker.html()
                        });
                    }
                }, this));
            } else {
                console.log('Maps initialize: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Get the current provider.
         */
        Maps.prototype.getProvider = function () {
            return this.settings.provider;
        };

        /**
         * Set the Maps map.
         */
        Maps.prototype.setMap = function () {
            if (this.getProvider() === 'leaflet') {
                this.map = L.map(this.$stage[0], {
                    zoom            : this.settings.zoom,
                    zoomControl     : false,
                    scrollWheelZoom : this.settings.scrollWheel
                });
            } else {
                console.log('Maps setMap: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set the Maps map.
         */
        Maps.prototype.getMap = function () {
            return this.map;
        };

        /**
         * Set the Maps map layers.
         */
        Maps.prototype.setLayers = function () {
            if (this.getProvider() === 'leaflet') {
                $.each(this.settings.views, $.proxy(function (index, view) {
                    // For Google Maps tiles we need to use 'hl' parameter instead of 'language'
                    var tile = this.settings.tiles.url.replace('{layer}', view === 'satellite' ? 's,h' : 'm');
                    
                    // Add proper Google Maps language parameter
                    if (tile.indexOf('?') === -1) {
                        tile += '?hl=' + this.language;
                    } else {
                        tile += '&hl=' + this.language;
                    }

                    this.layers[view] = L.tileLayer(tile, {
                        id          : 'map_' + view,
                        maxZoom     : 20,
                        subdomains  : this.settings.tiles.domains || []
                    });
                }, this));

                this.layers[this.settings.view].addTo(this.map);
            } else {
                console.log('Maps setLayers: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set the Maps map center.
         */
        Maps.prototype.setCenter = function (center) {
            if (center) {
                if (this.getProvider() === 'leaflet') {
                    this.map.setView(new L.LatLng(center.lat, center.lng));
                } else {
                    console.log('Maps setCenter: Provider ' + this.getProvider() + ' not supported');
                }
            }
        };

        /**
         * Get the Maps map center.
         */
        Maps.prototype.getCenter = function () {
            return this.map.getCenter();
        };

        /**
         * Set the Maps map interface controls.
         */
        Maps.prototype.setControls = function () {
            if (this.settings.zoomControl) {
                $('<div class="' + this.settings.zoomControlsClass + '" />').append(
                    $('<button role="button" class="zoom-in" title="' + this.lexicon('zoom_in') + '">' + this.lexicon('zoom_in') + '</button>').on('click', $.proxy(function () {
                        this.zoomIn();

                        return false;
                    }, this)),
                    $('<button role="button" class="zoom-out" title="' + this.lexicon('zoom_out') + '">' + this.lexicon('zoom_out') + '</button>').on('click', $.proxy(function () {
                        this.zoomOut();

                        return false;
                    }, this))
                ).appendTo(this.$element);
            }

            if (this.settings.viewControl) {
                if (this.settings.views.length >= 2) {
                    var $viewControls = $('<div class="' + this.settings.viewControlsClass + '" >').appendTo(this.$element);

                    $.each(this.settings.views, $.proxy(function (index, view) {
                        var id = Math.random().toString().substring(2, 8);

                        $('<input type="radio" name="maps-view" value="' + view + '" id="maps-view-' + id + '" />').on('change', $.proxy(function (event) {
                            this.setView(event.currentTarget.value);
                        }, this)).prop('checked', view === this.settings.view).appendTo($viewControls);

                        $('<label for="maps-view-' + id + '">' + this.lexicon(view) + '</label>').appendTo($viewControls);
                    }, this));
                }
            }

            if (this.settings.routeControl) {
                setTimeout($.proxy(function () {
                    var center = this.getCenter();

                    if (center) {
                        $('<a href="https://www.google.com/maps/dir//' + center.lat + '+' + center.lng + '" target="_blank" rel="noopener noreferrer" class="' + this.settings.routeControlClass + '" title="' + $.fn.Leaflet.lexicons.route + '">' + $.fn.Leaflet.lexicons.route + '</a>').appendTo(this.$element);
                    }
                }, this), 500);
            }
        };

        /**
         * Reset the Maps map.
         */
        Maps.prototype.reset = function () {
            this.setCenter(this.settings.center);
            this.setRadius();
            this.unsetMarkers();
        };

        /**
         * Set the Maps map zoom.
         */
        Maps.prototype.zoomIn = function () {
            if (this.getProvider() === 'leaflet') {
                this.map.setZoom(this.map.getZoom() + 1);
            } else {
                console.log('Maps zoomIn: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set the Maps map zoom.
         */
        Maps.prototype.zoomOut = function () {
            if (this.getProvider() === 'leaflet') {
                this.map.setZoom(this.map.getZoom() - 1);
            } else {
                console.log('Maps zoomOut: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set the Maps map view.
         */
        Maps.prototype.setView = function (view) {
            if (this.layers[view]) {
                if (this.getProvider() === 'leaflet') {
                    // Remove the current layer
                    this.map.removeLayer(this.layers[this.settings.view]);
                    
                    // Make sure the language parameter is set on the layer URL
                    var tileUrl = this.layers[view]._url;
                    if (tileUrl.indexOf('hl=') === -1) {
                        // If language parameter is not present, add it
                        if (tileUrl.indexOf('?') === -1) {
                            tileUrl += '?hl=' + this.language;
                        } else {
                            tileUrl += '&hl=' + this.language;
                        }
                        this.layers[view].setUrl(tileUrl);
                    }

                    // Add the new layer
                    this.map.addLayer(this.layers[view]);
                } else {
                    console.log('Maps setView: Provider ' + this.getProvider() + ' not supported');
                }

                this.settings.view = view;
            }
        };

        /**
         * Set a Maps map marker.
         */
        Maps.prototype.setMarker = function (lat, lng, options) {
            options = options || {};

            if (this.getProvider() === 'leaflet') {
                var center  = new L.LatLng(lat, lng);
                var icon    = this.settings.markerIcon;

                if (options.icon) {
                    icon.iconUrl = options.icon;
                }

                var marker  = new L.Marker(center, {
                    icon : L.icon(icon)
                });

                if (marker) {
                    marker.addTo(this.map);

                    if (options.popup) {
                        marker.bindPopup($('<div class="' + this.settings.popupClass + '">').append(options.popup)[0]);
                    }

                    if (options.callback) {
                        marker.on('click', function (marker) {
                            options.callback.call(this, marker);
                        });
                    }

                    this.markers.push(marker);
                }

                if (options.center || options.center === undefined) {
                    this.map.setView(center);
                }
            } else {
                console.log('Maps setMarker: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set Maps map markers.
         */
        Maps.prototype.setMarkers = function (markers) {
            if (this.getProvider() === 'leaflet') {
                markers.forEach($.proxy(function (marker) {
                    this.setMarker(marker.lat, marker.lng, marker.popup || null);
                }, this));
            } else {
                console.log('Maps setMarkers: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Unset Maps map markers.
         */
        Maps.prototype.unsetMarkers = function () {
            if (this.getProvider() === 'leaflet') {
                this.markers.forEach($.proxy(function (marker) {
                    this.map.removeLayer(marker);
                }, this));
            } else {
                console.log('Maps unsetMarkers: Provider ' + this.getProvider() + ' not supported');
            }

            this.markers = [];
        };

        /**
         * Update a Maps map marker.
         */
        Maps.prototype.updateMarker = function (lat, lng, state, options) {
            if (this.getProvider() === 'leaflet') {
                this.markers.forEach($.proxy(function (marker) {
                    var location = marker.getLatLng();

                    if (location.lat === parseFloat(lat) && location.lng === parseFloat(lng)) {
                        if (state) {
                            this.map.setZoom(18);
                            this.map.panTo(location);
                        }
                    }
                }, this));
            } else {
                console.log('Maps updateMarker: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Set the Maps map radius.
         */
        Maps.prototype.setRadius = function (radius, lat, lng) {
            if (this.getProvider() === 'leaflet') {
                if (this.radius) {
                    this.map.removeLayer(this.radius);
                }

                if (radius) {
                    var center = this.getCenter();

                    if (lat && lng) {
                        center = new L.LatLng(lat, lng);
                    }

                    this.radius = L.circle(center, $.extend({
                        radius  : radius * 1000,
                        center  : center
                    }, this.settings.radiusStyle)).addTo(this.map);
                } else {
                    this.radius = null;
                }
            } else {
                console.log('Maps setRadius: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Fit the Maps map bounds.
         */
        Maps.prototype.bindZoomMarkers = function () {
            if (this.getProvider() === 'leaflet') {
                if (this.markers.length >= 1) {
                    this.map.fitBounds(new L.featureGroup(this.markers).getBounds());
                }
            } else {
                console.log('Maps bindZoomMarkers: Provider ' + this.getProvider() + ' not supported');
            }
        };

        /**
         * Triggers an event.
         *
         * @param name
         * @param data
         * @param namespace
         */
        Maps.prototype.trigger = function (name, data, namespace) {
            var status = {};

            var handler = $.camelCase($.grep(['on', name, namespace], function (v) {
                return v;
            }).join('-').toLowerCase());

            var event = $.Event([name, 'maps', namespace || 'maps'].join('.').toLowerCase(),
                $.extend({
                    relatedTarget : this
                }, status, data)
            );

            if (!this._supress[name]) {
                this.register({
                    type : 'Event',
                    name : name
                });

                if (this.settings && typeof this.settings[handler] === 'function') {
                    this.settings[handler].call(this, event);
                }
            }

            return event;
        };

        /**
         * Registers an event or state.
         *
         * @param {Object} object - The event or state to register.
         */
        Maps.prototype.register = function (object) {
            if (object.type === 'Event') {
                if (!$.event.special[object.name]) {
                    $.event.special[object.name] = {};
                }

                if (!$.event.special[object.name].maps) {
                    var _default = $.event.special[object.name]._default;

                    $.event.special[object.name]._default = function (e) {
                        if (_default && _default.apply && (!e.namespace || e.namespace.indexOf('maps') !== -1)) {
                            return _default.apply(this, arguments);
                        }

                        return e.namespace && e.namespace.indexOf('maps') > -1;
                    };

                    $.event.special[object.name].maps = true;
                }
            }
        };

        /**
         * Suppresses events.
         *
         * @param event
         */
        Maps.prototype.suppress = function (events) {
            $.each(events, $.proxy(function (index, event) {
                this.supress[event] = true;
            }, this));
        };

        /**
         * Releases suppressed events.
         *
         * @param events
         */
        Maps.prototype.release = function (events) {
            $.each(events, $.proxy(function (index, event) {
                delete this.supress[event];
            }, this));
        };

        Maps.prototype.lexicon = function (key) {
            if (this.language && $.fn.Maps.lexicons[this.language][key]) {
                return $.fn.Maps.lexicons[this.language][key];
            }

            return key;
        }

        /**
         * The jQuery Plugin for the Maps Maps.
         */
        $.fn.Maps = function (option) {
            var args = Array.prototype.slice.call(arguments, 1);

            return this.each(function () {
                var $this = $(this);
                var data = $this.data('maps');

                if (!data) {
                    data = new Maps(this, typeof option === 'object' && option);

                    $this.data('maps', data);

                    $.each(['reset', 'setRadius', 'setMarker', 'setMarkers', 'unsetMarkers', 'updateMarker', 'bindZoomMarkers'], function (i, event) {
                        data.register({
                            type : 'Event',
                            name : event
                        });

                        data.$element.on(event + '.maps.core', $.proxy(function (e) {
                            if (e.namespace && this !== e.relatedTarget) {
                                this.suppress([event]);

                                data[event].apply(this, [].slice.call(arguments, 1));

                                this.release([event]);
                            }
                        }, data));
                    });
                }

                if (typeof option === 'string' && option.charAt(0) !== '_') {
                    data[option].apply(data, args);
                }
            });
        };

        /**
         * The constructor for the jQuery Plugin.
         */
        $.fn.Maps.Constructor = Maps;

        /**
         * The lexicons for the jQuery Plugin.
         */
        $.fn.Maps.lexicons = {
            nl: {
                zoom_in     : 'Zoom in',
                zoom_out    : 'Zoom uit',
                roadmap     : 'Kaart',
                satellite   : 'Satelliet',
                route       : 'Plan route'
            },
            de: {
                zoom_in     : 'Hineinzoomen',
                zoom_out    : 'Rauszoomen',
                roadmap     : 'Karte',
                satellite   : 'Satellit',
                route       : 'Route Planen'
            },
            en: {
                zoom_in     : 'Zoom in',
                zoom_out    : 'Zoom out',
                roadmap     : 'Roadmap',
                satellite   : 'Satellite',
                route       : 'Plan route'
            }
        };
    }(jQuery)
);
