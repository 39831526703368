const downloadBasketAddBtn = document.querySelectorAll('.btn-basket-add');
const downloadBasketDownloadBtn = document.querySelectorAll('.btn-basket-download');
const selectedNumber = document.querySelectorAll('.selected-number');
const clearSelectionBtn = document.querySelectorAll('.clear-selection');
const selectAllBtn = document.querySelectorAll('.select-all');
const toggleTitle = document.querySelectorAll('.toggle-title .filename');
const downloadFormatSelect = document.querySelector('.download-format');
const downloadBasketFileList = document.querySelector('.basket-file-list');
const toggleDownloadPanel = document.querySelector('.download-panel-toggle');

const checkboxSelectAllBtn = document.querySelector('.checkbox-select-all');
const checkboxSelectClearBtn = document.querySelector('.checkbox-select-clear');
const checkboxSelector = document.querySelector('.checkbox-selector');
const checkboxItems = document.querySelectorAll('.checkbox-item');
const checkboxAddAllBtn = document.querySelector('.checkbox-add-all');
const downloadPanel = document.getElementById('basket-download');
const maxBasketItems = 250;
const basketFullMessage = checkboxAddAllBtn?.dataset?.basketIsFull || '';
toggleDownloadPanel?.addEventListener('click', function (event) {
    downloadPanel.classList.toggle('show');
    toggleDownloadPanel.classList.toggle('open');
});

function downloadBasketRefresh()
{
    if (downloadBasketFileList === null) {
        return;
    }
    downloadBasketFileList.innerHTML = '';
    downloadBasketAddBtn?.forEach(function (button) {
        button.classList.remove('basket-has-items');
        button.removeAttribute('disabled');
    });
    let basket = [];
    let storageItem = localStorage.getItem('basket');
    if (storageItem) {
        basket = JSON.parse(storageItem) || [];
        basket.forEach(function (item) {
            const li = document.createElement('li');
            li.textContent = item.file;
            downloadBasketFileList.appendChild(li);
            let target = document.querySelector(`.btn-basket-add[data-doc="${item.doc}"]`);
            target?.classList.add('basket-has-items');
            target?.setAttribute('disabled', true);
        });
    }
    updateSelectionNumber(basket.length);
}

function addItemToBasket(element)
{
    const basket = JSON.parse(localStorage.getItem('basket')) || [];
    if (basket.length < maxBasketItems) {
        const doc = element.dataset.doc || null;
        const item = element.dataset.item || '--';
        const file = element.dataset.file || '--';
        basket.push({"doc": doc, "item": item, "file": file});
        localStorage.setItem('basket', JSON.stringify(basket));
        downloadBasketRefresh();
        return true;
    } else {
        alert(basketFullMessage);
        return false;
    }
}

downloadBasketAddBtn?.forEach(function (button) {
    button.addEventListener('click', function (event) {
        event.preventDefault();
        const element = event.target?.parentNode;
        if (addItemToBasket(element)) {
            element.classList.add('basket-has-items');
            element.setAttribute('disabled', true);
        }
    });
});

function updateSelectionNumber(number)
{
    selectedNumber.forEach((item) => {
        item.innerHTML = number;
    })
}

clearSelectionBtn?.forEach(function (button) {
    button.addEventListener('click', async function (event) {
        localStorage.setItem('basket', JSON.stringify([]));
        downloadBasketRefresh();
    });
});
toggleTitle?.forEach(function (button) {
    button.addEventListener('click', function (event) {
        event.target?.parentNode.classList.toggle('show-title');
    });
});
checkboxItems?.forEach(function (element) {
    element.addEventListener('click', function (event) {
        event.target.classList.toggle('checked')
        const checkboxCheckedItems = document.querySelectorAll('.checkbox-item.checked');
        if (checkboxCheckedItems.length === 0) {
            checkboxSelector.classList.remove('checked', 'mixed');
        } else if (checkboxCheckedItems.length === checkboxItems.length ) {
            checkboxSelector.classList.add('checked');
            checkboxSelector.classList.remove('mixed');
        } else {
            checkboxSelector.classList.add('checked', 'mixed');
        }
    });
});

checkboxSelectAllBtn?.addEventListener('click', function (event) {
    checkboxItems.forEach(function (element) {
        element.classList.add('checked');
    })
    checkboxSelector.classList.add('checked');
    checkboxSelector.classList.remove('mixed');
})
checkboxSelectClearBtn?.addEventListener('click', function (event) {
    checkboxItems.forEach(function (element) {
        element.classList.remove('checked');
    })
    checkboxSelector.classList.remove('checked', 'mixed');
})
checkboxAddAllBtn?.addEventListener('click', function (event) {
    const basket = JSON.parse(localStorage.getItem('basket')) || [];
    let checkboxCheckedItems = document.querySelectorAll('.checkbox-item.checked');
    for (let element of checkboxCheckedItems) {
        if (basket.length < maxBasketItems) {
            const doc = element.dataset.doc || null;
            const item = element.dataset.item || '--';
            const file = element.dataset.file || '--';
            basket.push({"doc": doc, "item": item, "file": file});
            element.classList.remove('checked');
        } else {
            alert(basketFullMessage);
            break;
        }
    }
    localStorage.setItem('basket', JSON.stringify(basket));
    checkboxCheckedItems = document.querySelectorAll('.checkbox-item.checked');
    if (checkboxCheckedItems.length === 0) {
        checkboxSelector.classList.remove('checked', 'mixed');
    } else if (checkboxCheckedItems.length !== checkboxItems.length) {
        checkboxSelector.classList.add('mixed');
    }
    downloadBasketRefresh();
})

selectAllBtn?.forEach(function (button) {
    button.addEventListener('click', async function (event) {
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        downloadBasketAddBtn.forEach(function (element) {
            const doc = element.dataset.doc || null;
            const item = element.dataset.item || '--';
            const file = element.dataset.file || '--';
            basket.push({"doc": doc, "item": item, "file": file});
        })
        localStorage.setItem('basket', JSON.stringify(basket));
        downloadBasketRefresh();
    });
});

downloadBasketDownloadBtn?.forEach(function (button) {
    button.addEventListener('click', async function (event) {
        event.preventDefault();
        const format = downloadFormatSelect.value || 'zip';
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        if (basket.length === 0) {
            return;
        }
        console.log(basket);
        const data = {};
        data.documents = basket;
        data.format = format;

        const response = await fetch('/api/v1/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }
        const filename = response.headers.get('Content-Disposition').split('=')[1];
        const blob = await response.blob();

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename.replaceAll('"', '');
        link.click();
        localStorage.removeItem('basket');
        downloadBasketRefresh();
    });
});

downloadBasketRefresh();
